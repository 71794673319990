'use client'

import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

export default function AppendRoot ({
  children,
  container
}) {

  const [mounted, setMouned] = useState(false)
  useEffect(() => {
    setMouned(true)
  }, [setMouned])

  const view = useMemo(() => {
    if (!mounted) return null
    return createPortal(children, container || document.body)
  }, [mounted, container, children])

  return view
}