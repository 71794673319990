'use client'

import { removeLoading } from '@utils'
import classNames from 'classnames'
import Image from 'next/image'

export default function Cover ({
  src,
  alt,
  width,
  height,
  className,
  imgClassName,
  defaultIcon,
  iconSize = '!text-4xl',
  hoverScale = false
}) {

  return (
    <div className={classNames('overflow-hidden max-w-full', className)}>
      {
        src
        ? (
          <div style={{aspectRatio: `${width}/${height}`}} className='w-full h-full'>
            <Image src={src || ''} alt={alt || ''} imgbg='loading' imgsize='sm' onLoad={removeLoading} onError={removeLoading} className={classNames('block object-cover w-full h-full', {'group-hover:scale-110 transition-transform duration-500': hoverScale && src}, imgClassName)} width={width} height={height}></Image>
          </div>
        )
        : (
          <div style={{aspectRatio: `${width}/${height}`}} className={classNames('w-full h-full', {'flex items-center justify-center bg-neutral-1 ': defaultIcon})}>
            {defaultIcon && <i className={classNames('icon text-neutral-5', defaultIcon, iconSize)}></i>}
          </div>
        )
      }
    </div>
    // src
    // ? <Image src={src || ''} alt={alt || ''} imgbg='loading' imgsize='sm' onLoad={removeLoading} onError={removeLoading} className={classNames({'group-hover:scale-110 transition-transform duration-500': hoverScale && src}, className)} width={width} height={height}></Image>
    // : <div className={className} style={{width, height, aspectRatio: `${width}/${height}`}}></div>
  )
}
