import { HOUSE_TYPE_ID } from '@config'
import { getTime } from '@utils'
import { isRuralData } from '@utils/house'
import classNames from 'classnames'

export default function Tags({
  data,
  typeTag = false,
  className
}) {

  const [listedToday, listedYesterday] = (() => {
    const { marketTime } = data
    const now = Math.floor(Date.now() / 1000)
    const yesterday = now - 60 * 60 * 24
    return [
      getTime(marketTime || 0, 'YYYYMMDD') === getTime(now, 'YYYYMMDD'),
      getTime(marketTime || 0, 'YYYYMMDD') === getTime(yesterday, 'YYYYMMDD'),
    ]
  })()

  return (
    <div className={classNames('flex flex-wrap gap-4', className)}>
      {
        typeTag
          ? (
            <>
              {data.typeId === HOUSE_TYPE_ID.sold && <TagItem className='bg-[#8A8A8A]'>Sold</TagItem>}
              {data.typeId === HOUSE_TYPE_ID.estimate && <TagItem className='bg-[#17A11A]'>Estimate</TagItem>}
              {data.typeId === HOUSE_TYPE_ID.rent && <TagItem className='bg-[#741a87]'>Rent</TagItem>}
              {data.typeId === HOUSE_TYPE_ID.residential && <TagItem className='bg-[#FF8500]'>Buy</TagItem>}
              {data.typeId === HOUSE_TYPE_ID.rural && <TagItem className='bg-[#FF8500]'>Buy</TagItem>}
              {[
                HOUSE_TYPE_ID['commercial-property-for-sale'],
                HOUSE_TYPE_ID['commercial-property-for-lease'],
                HOUSE_TYPE_ID['businesses-for-sale']
              ].includes(data.typeId) && <TagItem className='bg-[#FF8500]'>Buy</TagItem>}
            </>
          )
          : (
            <>
              {data.status === 2 && <TagItem className='bg-[#8A8A8A]'>Sold</TagItem>}
              {data.status === 3 && <TagItem className='bg-[#8A8A8A]'>Withdrawn or sold</TagItem>}
              {data.openHomes && <TagItem className='bg-[#17A11A]'>{isRuralData(data) ? 'Open Day' : 'Open Home'}</TagItem>}
              {data.isProject && <TagItem className='bg-[#741a87]'>New Homes</TagItem>}
              {!data.isProject && data.newHouse && <TagItem className='bg-[#741a87]'>New</TagItem>}
              {listedToday && <TagItem className='bg-[#F5A623]'>Listed today</TagItem>}
              {listedYesterday && <TagItem className='bg-[#F5A623]'>Listed yesterday</TagItem>}
              {(data.threeDView || data.video) && <TagItem className='bg-[#FF8500]'>Video available</TagItem>}
            </>
          )
      }
    </div>
  )
}

export function TagItem({
  children,
  className
}) {
  return (
    <div className={classNames('text-xs text-white px-6 py-1 rounded-sm inline-block', className)}>{children}</div>
  )
}