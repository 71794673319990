import { saveListing } from '@store/index'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useCallback } from 'react'
import { getHouseId } from '@utils/house'
import useUserState from './useUserState'
import { favouriteDataLayer } from '@plugin/google/gtm/modules/property'

export default function useSaveListing() {

  const state = useRecoilValue(saveListing)
  const setSaveListingState = useSetRecoilState(saveListing)
  const { pushSavedHouseId, removeSavedHouseId } = useUserState()

  // 保存房源
  const showSaveLising = useCallback(({ house, signupSource = 'Save_Property', showFolder = false }) => {
    return event => {
      event.preventDefault()
      event.stopPropagation()
      const houseId = getHouseId(house)
      favouriteDataLayer.show()
      setSaveListingState(d => ({ ...d, show: true, house, houseId, signupSource, showFolder }))
    }
  }, [setSaveListingState])

  // 缓存房源saved
  const changeSaved = useCallback(saved => {
    const { houseId } = state
    saved ? pushSavedHouseId(houseId) : removeSavedHouseId(houseId)
  }, [pushSavedHouseId, removeSavedHouseId, state])

  // tracking
  const toggleSaving = useCallback(saving => {
    setSaveListingState(d => ({ ...d, saving }))
  }, [setSaveListingState])

  // 隐藏save listing弹窗
  const hideSaveListing = useCallback(() => {
    setSaveListingState(d => ({ ...d, show: false }))
  }, [setSaveListingState])

  return {
    ...state,
    showSaveLising,
    changeSaved,
    toggleSaving,
    hideSaveListing
  }
}