'use client'

import { removeLoading } from '@utils'
import classNames from 'classnames'
import Image from 'next/image'

export default function Avatar({
  src,
  width,
  height,
  alt,
  className,
  circle = false,
  radius,
  imgClassName,
  defaultIcon,
  iconSize = '!text-base',
  ...args
}) {
  return (
    <div className={classNames('overflow-hidden flex-shrink-0', { 'rounded-half': circle }, className)} style={{ width, height, borderRadius: radius }}>
      {
        src
          ? <Image src={src} width={width} height={height} alt={alt} onLoad={removeLoading} onError={removeLoading} imgbg='loading' className={classNames('w-full h-full block object-cover object-[center_top]', { 'rounded-half': circle }, imgClassName)} {...args}></Image>
          : defaultIcon && (
            <div style={{ width, height, borderRadius: radius }} className={classNames('flex items-center justify-center bg-neutral-1 max-w-full max-h-full', { 'rounded-half': circle })}>
              <i className={classNames('icon text-neutral-5', defaultIcon, iconSize)}></i>
            </div>
          )
      }
    </div>
  )
}
