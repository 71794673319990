'use client'

import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Autoplay, Virtual } from 'swiper'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/swiper.css'
import { concat, get, isFunction, map, size } from 'lodash-es'
import classNames from 'classnames'

export const SWIPER_BREAKPOINTS = {
  2: (spaceBetween = 16) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: spaceBetween
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: spaceBetween
    }
  }),
  3: (spaceBetween = 16) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: spaceBetween
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: spaceBetween
    },
    750: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: spaceBetween
    }
  }),
  4: (spaceBetween = 16) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: spaceBetween
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: spaceBetween
    },
    750: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: spaceBetween
    },
    900: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: spaceBetween
    }
  }),
  5: (spaceBetween = 16) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: spaceBetween
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: spaceBetween
    },
    750: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: spaceBetween
    },
    900: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: spaceBetween
    }
  }),
  6: (spaceBetween = 16) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: spaceBetween
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: spaceBetween
    },
    750: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: spaceBetween
    },
    900: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: spaceBetween
    }
  }),
  'typeCard': {
    0: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 16
    },
    640: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: 16
    },
    900: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 16
    }
  }
}

export function getBreakpoints(slidesPerView, spaceBetween) {
  const point = SWIPER_BREAKPOINTS[slidesPerView]
  return point ? point(spaceBetween) : undefined
}

/**
 * 
 * @param {array} data 数据
 * @param {component} Card 卡片组件
 * @param {any} cardParams 卡片额外的参数
 * @param {string} dataKey 数据的唯一标识
 * @param {number} slidesPerView 同时显示数量
 * @param {number} slidesPerGroup 同时滚动数量
 * @param {number} spaceBetween 每个卡片的间距
 * @param {object} breakpoints 响应式尺寸设置
 * @param {boolean} showNext 手机模式下，是否露出下一个的一部分
 * @returns swiper
 */
export default function SectionSwiper({
  data,
  Card,
  cardParams = {},
  dataKey,
  slidesPerView = 4,
  slidesPerGroup = slidesPerView,
  spaceBetween = 16,
  breakpoints = getBreakpoints(slidesPerView, spaceBetween),
  swiperClassName,
  slideClassName,
  showNext = true,
  useNavigation = true,
  mobileNavigation = false,
  autoplay = false,
  onChange,
  slideClick,
  navClick,
  renderItem,
  virtual = false
}) {

  const [activeIndex, setActiveIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const [isEnd, setIsEnd] = useState(get(swiper, 'isEnd', false))

  function onSwiper(sw) {
    setSwiper(sw)
    setIsEnd(sw.isEnd)
  }

  function prevHandle() {
    swiper.slidePrev()
    setIsEnd(swiper.isEnd)
    isFunction(navClick) && navClick(swiper)
  }
  function nextHandle() {
    swiper.slideNext()
    setIsEnd(swiper.isEnd)
    isFunction(navClick) && navClick(swiper)
  }

  function changeHandle(swiper) {
    const { realIndex } = swiper
    setActiveIndex(realIndex)
    isFunction(onChange) && onChange(swiper)
  }

  // breakpoints
  // 修复一开始SwiperSlide没有计算宽度，导致只显示一个
  const slideStyle = { width: `calc((100% - ${spaceBetween * (slidesPerView - 1)}px) / ${slidesPerView} )`, marginRight: `${spaceBetween}px` }
  return data && data.length > 0 && (
    <div className='relative'>
      <Swiper
        className={classNames({ '!pr-120 sm:!pr-0': showNext }, swiperClassName)}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        breakpoints={breakpoints}
        onSwiper={onSwiper}
        autoplay={autoplay}
        modules={concat(virtual ? [Virtual] : [], autoplay ? [Autoplay] : [])}
        onSlideChange={changeHandle}
        virtual={virtual}
      >
        {
          map(data, (item, idx) => (
            <SwiperSlide
              key={dataKey ? `${item[dataKey]}${idx}` || idx : idx}
              className={slideClassName}
              style={slideStyle}
              onClick={isFunction(slideClick) ? () => slideClick(item, idx) : undefined}
              virtualIndex={virtual ? idx : undefined}
            >
              {
                isFunction(renderItem)
                  ? renderItem(item, idx)
                  : <Card data={item} {...cardParams} index={idx}></Card>
              }
            </SwiperSlide>
          ))
        }
      </Swiper>
      {
        useNavigation && (size(data) > slidesPerView || slidesPerView === 'auto') && (
          <>
            <NavigationButton
              onClick={prevHandle}
              left
              mobileNavigation={mobileNavigation}
              activeIndex={activeIndex}
              swiper={swiper}
              isEnd={isEnd}
            ></NavigationButton>
            <NavigationButton
              onClick={nextHandle}
              right
              mobileNavigation={mobileNavigation}
              activeIndex={activeIndex}
              swiper={swiper}
              isEnd={isEnd}
            ></NavigationButton>
          </>
        )
      }
    </div>
  )
}

function NavigationButton({
  onClick,
  left,
  right,
  mobileNavigation = false,
  activeIndex,
  swiper,
  isEnd = false
}) {

  if (activeIndex === 0 && left) return null
  if ((get(swiper, 'isEnd', false) || isEnd) && right) return null

  return (
    <div onClick={onClick} className={
      classNames(
        'absolute z-[1]  rounded-half bg-white shadow-md items-center justify-center cursor-pointer top-1/2 translate-y-[-50%]',
        mobileNavigation ? 'flex w-32 h-32 lg:w-48 lg:h-48' : 'w-48 h-48 hidden lg:flex',
        mobileNavigation
          ? { 'left-0 translate-x-0 lg:translate-x-[-40%]': left, 'right-0 translate-x-0 lg:translate-x-[40%]': right }
          : { 'left-0 translate-x-[-40%]': left, 'right-0 translate-x-[40%]': right },
      )
    }>
      {
        left && <i className={classNames('icon icon-arrow-left text-primary', mobileNavigation ? '!text-base lg:!text-2xl' : '!text-2xl')}></i>
      }
      {
        right && <i className={classNames('icon icon-arrow-right text-primary', mobileNavigation ? '!text-base lg:!text-2xl' : '!text-2xl')}></i>
      }
    </div>
  )
}

SectionSwiper.propTypes = {
  data: PropTypes.array.isRequired
}
