import classNames from 'classnames'
import { filter, isEmpty, isNumber, size } from 'lodash-es'

export default function HouseFacility({
  data,
  className,
  itemClassName,
  iconClassName,
  children
}) {

  return (data.bedrooms || data.bathrooms || data.carspaces || children)
    ? (
      <div className={classNames('flex items-center space-x-14', className)}>
        <FacilityItem num={data.bedrooms} icon='icon-bed' className={itemClassName} iconClassName={iconClassName}></FacilityItem>
        <FacilityItem num={data.bathrooms} icon='icon-bath' className={itemClassName} iconClassName={iconClassName}></FacilityItem>
        <FacilityItem num={data.carspaces} icon='icon-car' className={itemClassName} iconClassName={iconClassName}></FacilityItem>
        {
          size(filter(children, item => !isEmpty(item))) > 0 && (
            <>
              {(data.bedrooms || data.bathrooms || data.carspaces) ? <span className='text-xs text-font'>|</span> : undefined}
              {children}
            </>
          )
        }
      </div>
    )
    : null
}

export function FacilityItem({
  num,
  html,
  icon,
  svg,
  iconClassName,
  className
}) {

  return (
    (isNumber(num) && num > 0)
    || (/\d/g.test(num) && parseInt(num) > 0)
  ) && (
      <div className={classNames('space-x-6 text-font text-sm whitespace-nowrap', className)}>
        {
          icon && <i className={classNames('icon', icon, iconClassName)}></i>
        }
        {
          svg
        }
        {
          html
            ? <span dangerouslySetInnerHTML={{ __html: html || '' }}></span>
            : <span>{num}</span>
        }
      </div>
    )
}