'use client'

import map from 'lodash/map'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { isFunction } from 'lodash-es'
import { useBoolean, useClickAway } from 'react-use'
import MenuContext from './context'

export default function Menu({
  children,
  data,
  popup,
  width = 200,
  left = false,
  right = false,
  hover = true,
  className,
  contentClassName,
  popupClassName,
  dotClassName,
  onClose,
  onClick,
  onOpen,
  onMouseEnter,
  onMouseLeave,
  fixedBg = false
}) {

  const [visible, toggleVisible] = useBoolean(false)
  function toggleHandle(event) {
    event.preventDefault()
    switch (event.type) {
      case 'click':
        toggleVisible(!visible)
        isFunction(onClick) && onClick(event)
        break
      case 'mouseenter':
        if (hover) toggleVisible(true)
        isFunction(onMouseEnter) && onMouseEnter(event)
        break
      case 'mouseleave':
        if (hover) toggleVisible(false)
        isFunction(onMouseLeave) && onMouseLeave(event)
        break
    }
  }

  // 解决弹出框超出屏幕
  const popupRef = useRef(null)
  const [rectifyStyle, setRectifyStyle] = useState({})
  useEffect(() => {
    if (!visible) {
      setRectifyStyle({})
      return
    }
    const rect = popupRef.current.getBoundingClientRect()
    const winWidth = window.innerWidth
    const style = {}

    if (rect.left < 0) {
      style.transform = `translateX(${Math.abs(rect.left)}px)`
    }
    if (rect.right > winWidth) {
      style.left = winWidth - rect.right
      style.right = 'auto'
    }
    setRectifyStyle(style)
  }, [visible, setRectifyStyle, left, right, popupRef])


  useEffect(() => {
    if (visible && isFunction(onOpen)) onOpen()
    if (!visible && isFunction(onClose)) onClose()
  }, [visible, onClose, onOpen])

  const wrapRef = useRef()
  useClickAway(wrapRef, () => {
    toggleVisible(false)
  })

  return (
    <MenuContext.Provider value={{ visible, toggleVisible }}>
      <div className={classNames('relative', visible ? 'z-[110]' : 'z-10', className)} ref={wrapRef} onMouseEnter={toggleHandle} onMouseLeave={toggleHandle}>
        <div onClick={toggleHandle}>{
          isFunction(children) ? children(visible) : children
        }</div>
        {
          fixedBg && (
            <div
              className={classNames('fixed inset-x-0 inset-y-0 bg-black bg-opacity-50 backdrop-blur-sm sm:hidden', { 'hidden': !visible })}
              onClick={() => toggleVisible(false)}
            ></div>
          )
        }
        <div className={classNames('absolute top-full origin-top pt-14 w-full', { 'animate-expansion': visible, 'hidden': !visible })}>
          <i className={classNames('absolute z-[1] block w-0 h-0 -translate-y-full border-8 border-solid border-transparent border-b-white drop-shadow-menu-bottom left-1/2 -translate-x-1/2', dotClassName)}></i>
          <div className={classNames('absolute', { 'left-0': left, 'right-0': right }, popupClassName)} ref={popupRef} style={rectifyStyle}>
            <div className={classNames('bg-white shadow-menu max-w-[100vw]', contentClassName)} style={{ width }} onClick={() => toggleVisible(false)}>
              {
                map(data, (item, idx) => (
                  <div key={idx}>{item}</div>
                ))
              }
              {popup}
            </div>
          </div>
        </div>
      </div>
    </MenuContext.Provider>
  )
}