import { assign, clone, concat, each, filter, first, get, includes, isNaN, isNull, isString, isUndefined, last, map, mapValues, pickBy, replace, size, sortBy, split, toString } from 'lodash-es'
import SearchRoute from '../index'
import { suburbsToIds } from '../utils'
import { areaNumberToQuery, getHouseSearchTypeData, getPriceQuery, getSlug, joinStr, slugToId, toNumber } from '@utils'
import { HOUSE_FILTER_ALIAS } from '@config'
import { areaLableToNumber } from '@utils'
class HouseRoute extends SearchRoute {
  constructor() {
    super()
  }

  reset() {
    this.region = []
    this.district = []
    this.suburb = []
    this.bedroom = []
    this.bathroom = []
    this.carspaces = []
    this['price-from'] = null
    this['price-to'] = null
    // description
    this.description = null
    // [slug,...]
    this['property-type'] = []
    // ===============commercial 相关参数 begin==================
    // [slug,...]
    this.categories = []
    this.landarea = []
    this.floorarea = []
    // ===============commercial 相关参数 end==================
    this.surround = false
    // 1/0
    this.isLandHome = null
    // number
    this.typeId = null
    // slug
    this.order = null
    //on/off
    this.showmap = null
    // 首页recet activity/saved searches
    this.listTime = null
    this.listTimeCount = null
    this.showListTime = false
    this.page = 1
    return this
  }

  toQuery(pageSize) {
    const {
      region,
      district,
      suburb,
      bedroom,
      bathroom,
      surround,
      description,
      isLandHome,
      typeId,
      page,
      order,
      listTime,
      showListTime
    } = this

    const subrubIds = suburbsToIds(concat(region, district, suburb)).idStr()
    const bedrooms = arrayToQuery(bedroom)
    const bathrooms = arrayToQuery(bathroom)
    const carspaces = arrayToQuery(this.carspaces)
    const surrounding = surround ? 1 : undefined

    const query = assign(
      {
        ...subrubIds,
        bedrooms,
        bathrooms,
        carspaces,
        surrounding,
        description,
        typeId,
      },
      isNull(isLandHome) || isUndefined(isLandHome) ? undefined : { isLandHome, isProject: 1 },
      size(this['property-type']) > 0 ? { categoryId: verifyNumbers(arrayToQuery(this['property-type'])) } : undefined,
      size(this.categories) > 0 ? { categoryId: verifyNumbers(arrayToQuery(this.categories)) } : undefined,
      size(this.landarea) > 0 ? { landArea: landAreaHandle(arrayToQuery(this.landarea, true)) } : undefined,
      size(this.floorarea) > 0 ? { floorArea: landAreaHandle(arrayToQuery(this.floorarea, true)) } : undefined,
      pageSize ? { offset: (page - 1) * pageSize, limit: pageSize } : undefined,
      // price
      getPriceQuery(this['price-from'], this['price-to'], 'price'),
      // order
      { order: toNumber(slugToId(order)) },
      // listTime
      showListTime ? { listTime } : undefined
      // 是否newhomes
      // typeId === HOUSE_TYPE_ID['new-homes'] ? { isProject: 1 } : undefined
    )
    return pickBy(query, v => !(isNull(v) || isUndefined(v) || isNaN(v) || v === ''))
  }

  toSlug() {
    // 屏蔽description中的下划线
    if (this.description) {
      this.description = replace(this.description, /[_-]/g, ' ')
    }
    return super.toSlug()
  }

  /**
   * 设置价格
   * @param {string} price 0-1000000|1000000-2000000|2000000
   * @returns this
   * @example
   * 
   * setPrice('0-1000000') -> price-from=0&price-to=1000000
   * setPrice('1000000-2000000') -> price-from=1000000&price-to=2000000
   * setPrice('2000000') -> price-from=2000000
   * 
   */
  setPrice(price) {
    if (!price) return this

    const [from, to] = map(split(price, '-'), toNumber)
    if (from) this['price-from'] = from
    if (to) this['price-to'] = to

    return this
  }

  /**
   * 地图是否显示
   * @param {string} val 本地缓存数据是否打开地图
   * @returns boolean
   */
  mapVisible(val) {
    return this.showmap === 'on' || val === 'on'
  }
  /**
   * 更改地图显示
   * @param {boolean} visible 是否显示地图
   */
  changeMapVisible(visible = false) {
    this.showmap = visible ? 'on' : null
    return this
  }

  /**
   * 根据searchType设置typeId, 
   * 例如：(houses-for-sale|houses-for-rent|sold|rural|commercial-property-for-sale|commercial-property-for-lease|businesses-for-sale|new-homes)
   * houses-for-sale设置typeId = 1
   * @param {string} type 
   */
  setSearchTypeId(type) {
    let { typeId } = getHouseSearchTypeData({ type })
    if (typeId) this.typeId = typeId
    return this
  }
}

export default function getHouseRoute() {
  return new HouseRoute()
}


/**
 * slug转filter搜索
 * @param {string} slug url slug
 * @param {object} data houses/filter/meta接口返回的数据
 * @param {string} type 搜索列表type houses-for-sale
 * @returns filter query
 */
export function slugToFilter(slug, data, type) {
  if (!slug || !data || !type) return []

  const houseRoute = getHouseRoute()
  houseRoute.fromSlug(slug)
  houseRoute.setSearchTypeId(type)

  const filter = joinStr([
    slugToAlias(houseRoute.bedroom, data, HOUSE_FILTER_ALIAS.bedroom),
    slugToAlias(houseRoute.bathroom, data, HOUSE_FILTER_ALIAS.bathroom),
    slugToAlias(houseRoute.carspaces, data, HOUSE_FILTER_ALIAS.carspaces),
    slugToAlias(houseRoute['property-type'], data, HOUSE_FILTER_ALIAS.propertyType),
    slugToAlias(houseRoute.categories, data, HOUSE_FILTER_ALIAS.categories),
    slugToAlias(houseRoute.landarea, data, HOUSE_FILTER_ALIAS.landarea),
    slugToAlias(houseRoute.floorarea, data, HOUSE_FILTER_ALIAS.floorarea),
    priceToAlias(houseRoute['price-from'], houseRoute['price-to'], data)
  ], '_')

  houseRoute.bedroom = []
  houseRoute.bathroom = []
  houseRoute.carspaces = []
  houseRoute['property-type'] = []
  houseRoute.categories = []
  houseRoute.landarea = []
  houseRoute.floorarea = []
  houseRoute['price-from'] = null
  houseRoute['price-to'] = null

  return [
    filter ? filter : undefined,
    houseRoute.toQuery()
  ]
}


/**
 * filter转成params
 * @param {string|object} filter 
 * @param {object} data filter数据
 * @returns query
 */
export function filterToParams(filter, data, isSlug = false) {
  const query = {}
  if (!filter) return [query, filter]

  // 先转成filter对象
  filter = filterToObject(filter)
  // 逐一比较alias
  each(data, item => {
    _getVal(HOUSE_FILTER_ALIAS.bedroom, item, 'bedroom')
    _getVal(HOUSE_FILTER_ALIAS.bathroom, item, 'bathroom')
    _getVal(HOUSE_FILTER_ALIAS.carspaces, item, 'carspaces')
    _getVal(HOUSE_FILTER_ALIAS.propertyType, item, 'property-type', true)
    _getVal(HOUSE_FILTER_ALIAS.categories, item, 'categories', true)
    _getVal(HOUSE_FILTER_ALIAS.landarea, item, 'landarea')
    _getVal(HOUSE_FILTER_ALIAS.floorarea, item, 'floorarea')
    filterToPrice(filter, item, ([priceFrom, priceTo] = []) => {
      query['price-from'] = priceFrom
      query['price-to'] = priceTo
      Reflect.deleteProperty(filter, item.alias)
    })
  })

  function _getVal(alias, data, key, isSlug = false) {
    const val = get(filter, data.alias, [])
    if (alias.includes(data.alias)) {
      let arr = []
      each(data.children, item => {
        const v = toNumber(item.value)
        if (val.includes(v)) {
          if (isSlug) {
            arr.push(getSlug(item.label, item.value))
          } else {
            arr.push(v)
          }
        }
      })
      query[key] = arr
      Reflect.deleteProperty(filter, data.alias)
    }
  }

  return [query, filterToString(filter)]
}

function filterToPrice(filter, data, callBack) {
  const val = get(filter, data.alias, [])
  if (HOUSE_FILTER_ALIAS.price.includes(data.alias)) {
    if (size(val) === 0) return callBack()
    const min = first(val) === toNumber(first(data.children).value) ? undefined : first(val)
    const max = last(val) === toNumber(last(data.children).value) ? undefined : last(val)
    let minPrice
    let maxPrice
    each(data.children, item => {
      if (toNumber(item.value) === min) {
        minPrice = getItemMin(item)
      } else if (toNumber(item.value) === max) {
        maxPrice = getItemMax(item)
      }
    })
    callBack([minPrice, maxPrice])
  }
}

function arrayToQuery(array, isArea = false) {
  return joinStr(
    sortBy(
      map(array,
        item => toNumber(isArea ? areaNumberToQuery(item) : slugToId(item))
      )
    ),
    ','
  )
}

function landAreaHandle(str) {
  if (includes(str, '+')) {
    return encodeURIComponent(str)
  }
  return str
}

// 验证value是不是数字
function verifyNumbers(value, defaultValue) {
  const list = split(value, ',')
  const numberReg = /^\d{1,20}$/
  const noPass = list.some(item => {
    return !numberReg.test(item)
  })
  return noPass ? defaultValue : value
}

function slugToAlias(routeData, data, alias) {
  let res = []
  if (size(routeData)) {
    each(data, val => {
      if (alias.includes(val.alias)) {
        // landarea, floorarea 路由上的数据是 ['1000-2000', '10000-100000']
        // 需要转成对应value
        if (HOUSE_FILTER_ALIAS.landarea.includes(val.alias) || HOUSE_FILTER_ALIAS.floorarea.includes(val.alias)) {
          res.push(
            val.alias,
            joinStr(map(filter(val.children, item => routeData.includes(areaLableToNumber(item.label))), 'value'), ',')
          )
        } else {
          res.push(val.alias, joinStr(map(routeData, item => slugToId(item)), ','))
        }
      }
    })
  }
  return joinStr(res, '_')
}

/**
 * 获取price filter
 * @param {string} priceFrom 
 * @param {string} priceTo 
 * @param {object} data houses/filter/meta接口返回的数据
 * @returns price filter
 */
export function priceToAlias(priceFrom, priceTo, data) {
  if (!priceFrom && !priceTo) return

  let res = []
  each(data, item => {
    if (HOUSE_FILTER_ALIAS.price.includes(item.alias)) {
      let arr = []
      each(item.children, jtem => {
        const min = getItemMin(jtem)
        const max = getItemMax(jtem)
        if ((priceFrom || 0) <= min && (priceTo >= max || !priceTo)) {
          arr.push(toNumber(jtem.value))
        }
      })
      if (size(arr)) res.push(item.alias, joinStr(map(arr, item => slugToId(item)), ','))
    }
  })
  return joinStr(res, '_')
}
function getItemMin(data) {
  return toNumber(first(split(get(data, 'label'), '-')))
}
function getItemMax(data) {
  return toNumber(last(split(get(data, 'label'), '-')))
}
/**
 * 合并filter
 * @param {string} target lg_1,2,3,4,5,6,7,8,9_prn_1,2,3,4
 * @param {string} from prn_1,2,3,4,5,6,7
 * @returns lg_1,2,3,4,5,6,7,8,9_prn_1,2,3,4,5,6,7
 */
export function mergeFilter(target, from) {
  if (!from) return target

  const key = first(split(from, '_'))
  let val = target
  if (toString(target).includes(key)) {
    const reg = new RegExp(`${key}_(\\d+,?)+_?`, 'g')
    val = replace(replace(toString(target), reg, ''), /_$/, '')
  }
  return joinStr([val, from], '_')
}

/**
 * filter对象转成filter字符串
 * @param {object} filter filter obj
 * @returns prn_1,2,3,4,5,6,7
 */
export function filterToString(filter) {
  return joinStr(map(pickBy(filter, val => size(val) > 0), (val, key) => joinStr([key, val], '_')), '_')
}

/**
 * filter字符串转对象
 * @param {string} filter prn_1,2,3,4,5,6,7
 * @returns object
 */
export function filterToObject(filter) {
  if (isString(filter)) {
    const arr = split(filter, '_')
    const res = {}
    for (let i = 0; i < arr.length; i += 2) {
      const key = arr[i]
      const val = map(split(arr[i + 1], ','), item => toNumber(item))
      res[key] = val
    }
    return res
  } else {
    return filter ? clone(filter) : {}
  }
}

/**
 * 删除filter内指定的alias
 * @param {string|object} filter 
 * @param {array} alias filter 别名 HOUSE_FILTER_ALIAS
 * @returns filter object
 */
export function removeFilterAlias(filter, alias) {
  let filterObj = filterToObject(filter)
  each(alias, val => {
    Reflect.deleteProperty(filterObj, val)
  })
  return filterObj
}